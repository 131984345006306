<template>
  <div class="repaymen-detail">
    <BaseHeaderBar
      title="Rincian"
      :showCustomerService="false"
      backBtnType="2"
      :showBackButton="true"
      color="#FFF"
      bgColor="#FB4F5D"
    />
    <div class="content">
      <div class="top-block">
        <img src="@/assets/img/loan-status5.png" />
        <div class="info">
          <p class="p1">Nama Pengguna: {{ repaymentDetail.cudrstomerName }}</p>
          <p class="p2">
            {{ repaymentDetail.badrnkName }}（{{
              repaymentDetail.badrnkAccount
            }}）
          </p>
          <p class="p3">
            Jumlah pinjaman sudah ditransfer ke rekening bank ini
          </p>
        </div>
      </div>
      <div class="detail-list">
        <!-- 借款金额 -->
        <div class="info">
          <p>Nominal Pinjaman</p>
          <p>Rp{{ formatNumberWithCommas(repaymentDetail.lodranAmount) }}</p>
        </div>
        <!-- 贷款日期 -->
        <div class="info">
          <p>Waktu Pinjaman</p>
          <p>{{ repaymentDetail.ardrrivalTime }}</p>
        </div>
        <!-- 还款日期 -->
        <div class="info">
          <p>Waktu Pembayaran</p>
          <p>{{ repaymentDetail.redrpaymentTime }}</p>
        </div>

        <!-- 贷款期限 -->
        <div class="info">
          <p>Tenor Pinjaman</p>
          <p>{{ repaymentDetail.tidrmers }}</p>
        </div>
        <!-- 逾期天数 -->
        <div class="info">
          <p>Jumlah Hari Keterlambatan</p>
          <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
        </div>
        <!-- 逾期罚息 -->
        <div class="info">
          <p>Denda Keterlambatan</p>
          <p>
            Rp{{
              formatNumberWithCommas(repaymentDetail.ovdrerdueInterestpenalty)
            }}
          </p>
        </div>
        <!-- 逾期违约金 -->
        <div class="info last">
          <p>Biaya Penundaan Keterlambatan</p>
          <p>
            Rp{{
              formatNumberWithCommas(repaymentDetail.ovdrerdueLiquidateddamages)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'RepaymenDetail',
  mixins: [webviewBack],
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.repaymen-detail {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @theme-color;
}

.content {
  width: 678px;
  flex: 1;
  margin: 31px auto 92px;
  box-sizing: border-box;
  background: url(../assets/img/repay-bg.png) no-repeat;
  background-size: contain;
}

.top-block {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-left: 29px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0 0;
  margin: 20px 5px 0 16px;
  background-color: #f7f7f7;

  img {
    width: 85px;
    height: 74px;
  }

  .info {
    margin-left: 18px;

    p {
      &:nth-child(n + 2) {
        margin-top: 6px;
      }
    }

    .p1 {
      font-size: 26px;
      color: #333;
      width: 500px;
    }

    .p2 {
      font-size: 32px;
      font-weight: 500;
      color: #333;
      width: 500px;
      word-break: break-all;
    }

    .p3 {
      font-size: 24px;
      color: #999;
      width: 500px;
      word-break: break-all;
    }
  }
}

.detail-list {
  margin: 17px 5px 0 16px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    &:nth-child(odd) {
      background-color: @body-bg-color;
    }

    &:nth-child(even) {
      background-color: #fff;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}
</style>
